import React from "react"
import { DiGithubBadge } from "react-icons/di"
import { FaLinkedin } from "react-icons/fa"
import { FaSpotify } from "react-icons/fa"

export const mainMenuItems = [
  {
    path: "/",
    title: "home",
  },
  {
    path: "/about",
    title: "about",
  },
  {
    path: "/journal",
    title: "journal",
  },
  // {
  //   path: "/contact",
  //   title: "contact",
  // },
]

export const socialMenuItems = [
  {
    icon: <FaLinkedin />,
    url: "https://www.linkedin.com/in/timothy-mechaley-34197687/",
    name: "LinkedIn",
  },
  // {
  //   icon: <FaTwitter />,
  //   url: "https://www.twitter.com",
  //   name: "Twitter",
  // },
  {
    icon: <DiGithubBadge />,
    url: "https://github.com/timmy3421",
    name: "GitHub",
  },
  {
    icon: <FaSpotify />,
    url: "https://open.spotify.com/artist/439jIcB8IoZC9z6iDBl2Td",
    name: "ZUNEG and the doom choir on Spotify",
  },
]

export const footerMenuItems = [
  {
    path: "/privacy",
    title: "privacy",
  },
  {
    path: "/cookies",
    title: "cookies",
  },
]
